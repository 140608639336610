import siteicons from "../assets/siteicons";

export const locationMenu = [
  {
    title: "Overview",
    id: "location-overview",
    skip_check: true,
  },
  {
    title: "Care",
    id: "location-cards",
    key: "reviews",
    skip_check: true,
  },
  {
    title: "Reviews",
    id: "location-review",
    key: "reviews",
    skip_check: true,
  },
  {
    title: "Team",
    id: "location-members",
    skip_check: true,
    key: "team",
  },
  {
    title: "News & Events",
    id: "location-events",
    skip_check: true,
    key: "locationBlog",
  },
  {
    title: "CQC Rating",
    id: "location-rating",
    skip_check: true,
    key: "locationBlog",
  },
  {
    title: "Covid Info",
    id: "location-covid",
    skip_check: false,
    key: "covid_info",
  },
  {
    title: "Faqs",
    id: "location-faqs",
    skip_check: false,
    key: "FAQs",
  },
];

export const careProviderMenu = [
  {
    title: "Overview",
    id: "provider-overview",
    skip_check: true,
  },
  {
    title: "Location",
    id: "provider-location",
    skip_check: true,
  },
];

export const footerMenu = [
  {
    title: "Homes",
    list: [
      {
        title: "Residential Care Homes",
        to: "",
      },
      {
        title: "Nursing Care Homes",
        to: "",
      },
      {
        title: "Dementia Care Homes",
        to: "",
      },
      {
        title: "Respite Care Homes",
        to: "",
      },
    ],
  },
  {
    title: "RETIREMENT HOMES",
    list: [
      {
        title: "All Retirement Homes",
        to: "",
      },
      {
        title: "Retirement Housing",
        to: "",
      },
      {
        title: "Retirement Communities",
        to: "",
      },
      {
        title: "Retirement Communities",
        to: "",
      },
    ],
  },
  {
    title: "ABOUT Care home",
    list: [
      {
        title: "Careers",
        to: "",
      },
      {
        title: "Our Mission",
        to: "",
      },
      {
        title: "How We Work",
        to: "",
      },
      {
        title: "Meet Our Team",
        to: "",
      },
    ],
  },
];

export const staticFilterData = {
  facilities: [
    "Close to Local shops",
    "Gardens for residents",
    "Lift",
    "Minibus or other transport",
    "Near Public Transport",
    "Pets by arrangement",
    "Television point in own room",
  ],
  care_categories: [
    "AIDS HIV",
    "Alzheimer",
    "Cancer Care",
    "Colitis or Crohns Disease",
    "Epilepsy",
    "Head Brain Injury",
    "Hearing Impairment",
    "Huntingtons Disease",
    "Motor Neurone Disease",
    "Multiple Sclerosis",
    "Parkinsons Disease",
  ],
  residencies: ["Respite", "Permanent"],
  service_types: ["Residential", "Nursing"],
  care_types: ["Nursing", "Elderly", "Dementia", "Mental Health"],
  room_size: [
    {
      title: "Small under 30",
      key: "small",
    },
    {
      title: "Medium 30 - 60",
      key: "medium",
    },
    {
      title: "Large over 60",
      key: "large",
    },
  ],
};

export const rawLocationFilters = {
  fetched: false,
  keys: {
    search_term: "",
    city: "",
    address_1: "",
    address_2: "",
    min: "",
    max: "",
    page: "1",
    care_category: "",
    residency_type: "",
    care_type: "",
    room_size: "",
    distance: "",
  },
};

export const staticMenu = [
  {
    title: "Home",
    key: "mi_11",
    list: [],
    to: "/",
    has_link: true,
  },
  {
    title: "Care Search",
    key: "mi_1",
    list: [],
    to: "/search?page=1",
    has_link: true,
  },
  {
    title: "Signup",
    key: "mi_3",
    list: [],
    to: "/signup",
    has_link: true,
  },
];

export const staticMobileMenu = [
  {
    title: "Home",
    key: "mi_11",
  },
  {
    title: "Care Search",
    key: "mi_1",
  },
  {
    title: "Care Advice",
    key: "mi_2",
  },
  {
    title: "Signup",
    key: "mi_3",
  },
];

export const googlePostCode = ["postal_code"];

export const googleAreaCode = ["postal_code_prefix"];

export const googleCounty = ["administrative_area_level_2"];

export const googleCity = ["postal_town", "locality", "sublocality"];

export const googleDistrict = [
  "sublocality_level_1",
  "sublocality_level_2",
  "sublocality_level_3",
  "sublocality_level_4",
  "political",
];

export const addressTypeKey = {
  City: "town",
  Postcode: "postcode",
  District: "district",
  County: "county",
  Areacode: "areacode",
};

export const filterTypeKey = {
  City: "city",
  Postcode: "postcode",
  District: "district",
  County: "county",
  Areacode: "areacode",
};

export const staticRooms = [
  {
    type: "Respite Care",
    price: "120",
    is_available: true,
  },
  {
    type: "Cancer Care",
    price: "120",
    is_available: false,
  },
  {
    type: "Residential",
    price: "120",
    is_available: false,
  },
  {
    type: "Residential Dementia",
    price: "120",
    is_available: false,
  },
  {
    type: "Nursing",
    price: "120",
    is_available: false,
  },
  {
    type: "Nursing Dementia",
    price: "120",
    is_available: false,
  },
];

export const enquiryTypes = [
  {
    title: "Callback Enquiry",
    key: "Callback",
    description:
      "Would like us to call you back? Drop your enquiry and we will get in touch.",
  },
  {
    title: "Care Enquiry",
    key: "Care",
    description:
      "Have queries about the care provided or you need a different care service? Submit an enquiry.",
  },
  {
    title: "Job Enquiry",
    key: "Job",
    description:
      "Looking for a job in the care home? Drop an enquiry and the team will revert soon.",
  },
  {
    title: "Book Tour Enquiry",
    key: "Tour",
    description:
      "Not sure about the care home? Book a tour and visit the care home.",
  },
];

export const enquiryStepHeader = {
  1: "Select Enquiry Type",
  2: "Enter Details",
  3: "Success",
};

export const enquiryStepMessage = {
  1: "You'll receive support based on your enquiry",
  2: "Please fill in your details",
  3: "Enquiry has been submitted successfully. Our team will get in touch with you really soon.",
};

export const staticWhyList = [
  {
    title: "Free Expert Advice",
    description: `Receive honest and impartial advice from Lottie's team of experts, who know everything there is to know about the world of care homes and retirement living. Grab a cuppa and let's chat!`,
    button: "Search",
  },
  {
    title: "Free Expert Advice",
    description: `Receive honest and impartial advice from Lottie's team of experts, who know everything there is to know about the world of care homes and retirement living. Grab a cuppa and let's chat!`,
    button: "View",
  },
  {
    title: "Free Expert Advice",
    description: `Receive honest and impartial advice from Lottie's team of experts, who know everything there is to know about the world of care homes and retirement living. Grab a cuppa and let's chat!`,
    button: "FAQs",
  },
];

export const staticHowList = [
  {
    title: "Personalise Your Search",
    subtitle: "Step 1",
    description: `Tell us your later living needs, wants and budget, as well as your preferred location and estimated move-in date.`,
  },
  {
    title: "Compare Properties",
    subtitle: "Step 1",
    description: `Tell us your later living needs, wants and budget, as well as your preferred location and estimated move-in date.`,
  },
  {
    title: "Enquire Directly",
    subtitle: "Step 1",
    description: `Tell us your later living needs, wants and budget, as well as your preferred location and estimated move-in date.`,
  },
];

export const careHomesList = {
  0: ["Home One", "Home One", "Home One", "Home One", "Home One"],
  1: [
    "Care Category 1",
    "Care Category 1",
    "Care Category 1",
    "Care Category 1",
    "Care Category 1",
  ],
};

export const careHomesMenu = ["Popular Care Homes", "Care Categories"];

export const searchMenu = [
  { id: "location", title: "Search by Location" },
  { id: "title", title: "Search by Title" },
];

export const reviewKeys = [
  "first_name",
  "last_name",
  "email",
  "contact_no",
  "location_id",
  "company_id",
  "review",
  "rating",
  "relationship_to_resident",
];

export const CareStage = ["Prospecting", "Qualified", "Visit", "Closed"];
export const CareFor = [
  "Myself",
  "A parent",
  "A partner",
  "Another family member",
  "A friend",
  "A client",
];
export const CareRequired = [
  "Immediately",
  "1 week - 1 month",
  "1-3 month",
  "3-6 month",
  "6 months +",
];
export const CareFunding = [
  "Self Funding (Private)",
  "Local Authority with Top Up",
  "Local Authority",
  "NHS Continuing Healthcare (CHC)",
  "Not sure",
];
export const CareType = [
  "Older person residential",
  "Older person residential dementia",
  "Older person nursing",
  "Older person nursing dementia",
  "Other types of care",
  "Not sure",
];
export const CareLength = ["Permanent/Long-term", "Respite/Short-term"];

export const nearbyList = [
  {
    title: "Bus Stop",
    key: "nearby_bus_stop",
    icon: siteicons.IconBus.src,
  },
  {
    title: "Train Station",
    key: "nearby_train",
    icon: siteicons.IconTrain.src,
  },
  {
    title: "Local Pub",
    key: "nearby_local_pub",
    icon: siteicons.IconPub.src,
  },
  {
    title: "Shops",
    key: "nearby_shops",
    icon: siteicons.IconShop.src,
  },
];

export const specList = [
  {
    title: "Purpose Built",
    is_boolean: true,
    key: "purpose_built",
    icon: siteicons.IconPurpose.src,
  },
  {
    title: "Rooms with Ensuite",
    is_boolean: true,
    key: "ensuite",
    icon: siteicons.IconEnsuite.src,
  },
  {
    title: "Bed Vacancy",
    is_boolean: false,
    key: "bed_vacancies",
    icon: siteicons.IconSingleRoom.src,
  },
  {
    title: "Single Rooms",
    is_boolean: false,
    key: "shared_rooms",
    icon: siteicons.IconRoom.src,
  },
  {
    title: "Shared Rooms",
    is_boolean: false,
    key: "shared_rooms",
    icon: siteicons.IconRoom.src,
  },
  {
    title: "Ensuite Rooms",
    is_boolean: false,
    key: "ensuite_rooms",
    icon: siteicons.IconRoom.src,
  },
  {
    title: "All Inclusive",
    is_boolean: true,
    key: "all_inclusive",
    icon: siteicons.IconRoom.src,
  },
];

export const relationList = [
  "Resident / Service User",
  "Respite Resident/Service User",
  "Day Care Service User",
  "Friend of Resident/Service User",
  "Brother of Resident/Service User",
  "Sister of Resident/Service User",
  "Wife of Resident/Service User",
  "Husband of Resident/Service User",
  "Partner of Resident/Service User",
  "Civil Partner of Resident/Service User",
  "Son of Resident/Service User",
  "Daughter of Resident/Service User",
  "Grandson of Resident/Service User",
  "Granddaughter of Resident/Service User",
  "Great-grandson of Resident/Service User",
  "Great-granddaughter of Resident/Service User",
  "Nephew of Resident/Service User",
  "Niece of Resident/Service User",
  "Great-Nephew of Resident/Service User",
  "Great-Niece of Resident/Service User",
  "Great Great-Nephew of Resident/Service User",
  "Great Great-Niece of Resident/Service User",
  "Father of Resident/Service User",
  "Mother of Resident/Service User",
  "Grandfather of Resident/Service User",
  "Grandmother of Resident/Service User",
  "Aunt of Resident/Service User",
  "Uncle of Resident/Service User",
  "Son-in-law of Resident/Service User",
  "Daughter-in-law of Resident/Service User",
  "Father-in-law of Resident/Service User",
  "Mother-in-law of Resident/Service User",
  "Brother-in-law of Resident/Service User",
  "Sister-in-law of Resident/Service User",
  "Stepson of Resident/Service User",
  "Stepdaughter of Resident/Service User",
  "Stepbrother of Resident/Service User",
  "Stepsister of Resident/Service User",
  "Stepfather of Resident/Service User",
  "Stepmother of Resident/Service User",
  "Cousin of Resident/Service User",
  "Widow / Widower",
  "Relative of Resident/Service User",
  "Guardian of Resident/Service User",
  "Godfather of Resident/Service User",
  "Godmother of Resident/Service User",
  "Godson of Resident/Service User",
  "Goddaughter of Resident/Service User",
  "Power of Attorney of Resident/Service User",
  "Ex Family Member",
  "Private Carer of Resident/Service User",
  "IMCA (Independent Mental Capacity Advocate)/Deputyship",
  "Vicar / Church Minister",
  "Solicitor of Resident/Service User",
  "Regular Volunteer",
  "Next of Kin",
];
