import IconHome from "../public/icons/home.svg";
import IconBed from "../public/icons/bed.svg";
import IconPhone from "../public/icons/phone.svg";
import IconView from "../public/icons/view.svg";
import IconBus from "../public/icons/bus.svg";
import IconShop from "../public/icons/shop.svg";
import IconPub from "../public/icons/pub.svg";
import IconTrain from "../public/icons/train.svg";
import IconOccupant from "../public/icons/occupant.svg";

import IconFacility from "../public/icons/facility.svg";
import IconRoom from "../public/icons/bed.svg";
import IconSingleRoom from "../public/icons/single-room.svg";
import IconEnsuite from "../public/icons/ensuite.svg";
import IconPurpose from "../public/icons/purpose-built.svg";
import IconCare from "../public/icons/care.svg";
import IconPrice from "../public/icons/price.svg";
import IconFunding from "../public/icons/funding.svg";
import IconProvider from "../public/icons/provider.svg";
import IconAuthority from "../public/icons/authority.svg";
import IconAccount from "../public/icons/account.svg";
import IconTickCircle from "../public/icons/circle-tick.svg";
import IconVideo from "../public/icons/video.svg";

export default {
  IconBed,
  IconHome,
  IconPhone,
  IconView,
  IconBus,
  IconShop,
  IconPub,
  IconTrain,
  IconFacility,
  IconRoom,
  IconSingleRoom,
  IconEnsuite,
  IconPurpose,
  IconCare,
  IconPrice,
  IconFunding,
  IconOccupant,
  IconProvider,
  IconAuthority,
  IconAccount,
  IconTickCircle,
  IconVideo,
};
